import React, { useState } from 'react';
import { Edit } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Button from 'components/library/Button';
import Modal from 'components/Modal';
import { addToast } from 'services/store/features/toasts/actions';
import { updateProjectData, updateProjectImportantDate } from 'services/network/api/campaign';
import {
  Campaign, Event, Project, Task,
} from 'types/campaign';
import { keysToCamel, keysToSnake } from 'utils/converter';

import styles from './CampaignConfigurationEdition.module.css';

interface Props {
  data: {
    importantDate?: Event,
    introduction?: {
      fr: string,
      en: string,
    },
  },
  setCampaigns: (data: {
    campaign: Campaign[], project: Project, task?: Task
  } | ((curr: {
    campaign: Campaign[], project: Project, task?: Task
  }) => { campaign: Campaign[], project: Project, task?: Task })) => void,
}

const schema = yup.object().shape({
  importantDate: yup.object().shape({
    dryRunDate: yup.string(),
    earlyStartDate: yup.string(),
    event1Date: yup.string(),
    event2Date: yup.string(),
    expectedEndDate: yup.string(),
    lateStartDate: yup.string(),
    nextStartDate: yup.string(),
    realEndDate: yup.string(),
    realStartDate: yup.string(),
  }),
  introduction: yup.object().shape({
    fr: yup.string(),
    en: yup.string(),
  }),
});

type Configuration = yup.InferType<typeof schema>;

export default function CampaignConfigurationEdition({ data, setCampaigns }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const { register, handleSubmit } = useForm<Configuration>({
    resolver: yupResolver(schema),
    defaultValues: data,
  });

  const submit = async (values: Configuration) => {
    if (!id) {
      throw new Error('No id provided');
    } else {
      setIsLoading(true);
      try {
        await Promise.all([
          updateProjectData(id, { introduction: values.introduction }),
          updateProjectImportantDate(id, keysToSnake(values.importantDate)),
        ]);
        setIsOpen(false);
        setIsLoading(false);
        dispatch(addToast({
          type: 'success',
          title: 'La configuration de la campagne a bien été mise à jour',
          description: '',
        }) as any);
        setCampaigns((curr) => ({
          ...curr,
          project: {
            ...curr.project,
            introduction: {
              ...curr.project.introduction,
              ...keysToCamel(values.introduction),
            },
            event: {
              ...curr.project.event,
              ...keysToCamel(values.importantDate),
            },
          },
        }));
      } catch (err) {
        setIsLoading(false);
        dispatch(addToast({
          type: 'error',
          title: 'Une erreur est survenue lors de la mise à jour de la campagne',
          description: '',
        }) as any);
      }
    }
  };

  if (isOpen) {
    return (
      <Modal onClose={() => setIsOpen(false)}>
        <div className={styles.container}>
          <div>
            <p className={styles.title}>
              Dates importantes de la campagne
            </p>
            <form className={styles.form}>
              <label htmlFor="dryRunDate">
                Date dry run
                <input
                  type="date"
                  {...register('importantDate.dryRunDate')}
                />
              </label>
              <label htmlFor="earlyStartDate">
                Date de lancement (au plus tôt)
                <input
                  type="date"
                  {...register('importantDate.earlyStartDate')}
                />
              </label>
              <label htmlFor="event1Date">
                Date de l&apos;évènement 1
                <input
                  type="text"
                  {...register('importantDate.event1Date')}
                />
              </label>
              <label htmlFor="event2Date">
                Date de l&apos;évènement 2
                <input
                  type="text"
                  {...register('importantDate.event2Date')}
                />
              </label>
              <label htmlFor="expectedEndDate">
                Date de fin prévue
                <input
                  type="date"
                  {...register('importantDate.expectedEndDate')}
                />
              </label>
              <label htmlFor="lateStartDate">
                Date de lancement (au plus tard)
                <input
                  type="date"
                  {...register('importantDate.lateStartDate')}
                />
              </label>
              <label htmlFor="nextStartDate">
                Prochaine date de lancement (au plus tard)
                <input
                  type="date"
                  {...register('importantDate.nextStartDate')}
                />
              </label>
              <label htmlFor="realEndDate">
                Date de fin réelle
                <input
                  type="date"
                  {...register('importantDate.realEndDate')}
                />
              </label>
              <label htmlFor="realStartDate">
                Date de lancement réelle
                <input
                  type="date"
                  {...register('importantDate.realStartDate')}
                />
              </label>
            </form>
          </div>
          <div>
            <p className={styles.title}>
              Textes d&apos;introduction
            </p>
            <form className={styles['form-textarea']}>
              <label htmlFor="fr">
                Texte d&apos;introduction (fr)
                <textarea
                  {...register('introduction.fr')}
                />
              </label>
              <label htmlFor="em">
                Texte d&apos;introduction (en)
                <textarea
                  {...register('introduction.en')}
                />
              </label>
            </form>
          </div>
          <Button onClick={handleSubmit(submit)} disabled={isLoading}>
            {
              isLoading
                ? <span className="spinner white" />
                : 'Sauvegarder'
            }
          </Button>
        </div>
      </Modal>
    );
  }

  return (
    <button
      type="button"
      aria-label="Edit"
      onClick={() => setIsOpen(true)}
      className={styles.button}
    >
      <Edit className={styles.icon} />
    </button>
  );
}
