import axios from 'axios';
import axiosRetry from 'axios-retry';

import domains from '../../global';

const axiosMockClient = axios.create({
  baseURL: domains.url,
  timeout: 120000,
});

export const axiosAuthClient = axios.create({
  baseURL: `${domains.auth}/api`,
  timeout: 60000,
});

axiosMockClient.interceptors.request.use(
  async (config) => {
    const accessToken = window.sessionStorage.getItem('auth_token');
    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
      };
    }
    // eslint-disable-next-line consistent-return
    return config;
  },
  (error) => Promise.reject(error),
);

const verifyRefreshToken = async (originalRequest, axiosResponse) => {
  const refreshToken = window.localStorage.getItem('auth_refresh');
  /* Get new token by using the refresh token */
  if (refreshToken) {
    try {
      const { data } = await axiosAuthClient.post('/auth/refresh_token', { refresh_token: refreshToken });
      window.sessionStorage.setItem('auth_token', data.data.token);
      window.localStorage.setItem('auth_refresh', data.data.refresh_token);
      axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;
      return await axiosMockClient(originalRequest);
    } catch (err) {
      document.location.assign(`${domains.auth}?redirect=${window.location.href}`);
    }
    return Promise.reject(new Error(axiosResponse.data?.msg));
  }
  /* no refresh token, user need to log in */
  document.location.assign(`${domains.auth}?redirect=${window.location.href}`);
  return null;
};

const handleResponse = async (axiosResponse) => {
  const originalRequest = axiosResponse?.config;
  /* Token invalid */
  if (
    axiosResponse?.data?.status === 'KO' && axiosResponse?.data?.code === '401'
    // eslint-disable-next-line no-underscore-dangle
    && !originalRequest._retry
  ) {
    // eslint-disable-next-line no-underscore-dangle
    originalRequest._retry = true;
    await verifyRefreshToken(originalRequest, axiosResponse);
  }
  return axiosResponse;
};

const handleError = async (axiosError) => {
  const originalRequest = axiosError.response?.config;
  /* Token invalid */
  // eslint-disable-next-line no-underscore-dangle
  if (axiosError.response?.status === 401 && !originalRequest._retry) {
    // eslint-disable-next-line no-underscore-dangle
    originalRequest._retry = true;
    await verifyRefreshToken(originalRequest, axiosError.response);
  }
  return Promise.reject(axiosError);
};

axiosMockClient.interceptors.response.use(
  handleResponse,
  (error) => handleError(error),
);

axiosRetry(axiosMockClient, {
  retries: 3,
  shouldResetTimeout: true,
  retryCondition: (error) => {
    const isTimeout = error.code === 'ECONNABORTED';
    const isServerError = error.response && error.response.status >= 500;
    return isTimeout || isServerError;
  },
  retryDelay: (retryCount) => retryCount * 1000,
});

export default axiosMockClient;
